.myload{    border: none; 
}
.loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: transparent url('../common/images/loader.gif') no-repeat 50%
		50%;
	width: inherit;
	height: inherit
}

.myload {
	margin: 0px;
	padding: 0px;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	cursor: wait;
	position: fixed;
	background-color: rgba(158, 142, 142, 0.18);
	z-index: 99999999999999
}
.loadingmsg {
	text-align: center;
	margin-top: 20px;
	position: fixed;
	left: 0;
	top: 53%;
	width: 100%;
	height: 100%
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Loader Icon CSS Start*/

.ajax_loader_wrapper {
	background: rgba(255, 255, 255, 0.7) !important;
	height: 100%;
	position: fixed;
	width: 100%;
	z-index: 100000
}

.ajax_loader {
	display: block;
	left: 50%;
	margin: -32px 0 0 -32px;
	position: absolute;
	top: 50%;
	z-index: 100
}