.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.containerBox {
  border: 1px solid #a8a8a8;
  background-color: white;
}

.textCenter {
  text-align: -moz-center;
  text-align: -webkit-center;
  text-align: -ms-ime-center;
}

@media all and (-ms-high-contrast: none) {
  .customCSS {
    margin-left: .9rem
  }
}

@-moz-document url-prefix() {
  .fireCss {
    display: block !important;
    position: relative;
    top: .5rem;
    color: darkblue;
  }
}

@supports (-ms-ime-align:auto) {
  .customCSS {
    margin-left: .8rem
  }
}

.Dropdown-root {
  width: 100%;
  display: inline-block;
  line-height: 1.2;
  position: relative;
  top: 0.5rem;
  left: 0.5rem;
}

.Dropdown-control {
  min-width: 5rem;
  padding: 4px 25px 4px 10px !important;
  border: 1px solid grey !important;
  border-radius: 3px !important;
}

.Dropdown-root {
  left: 0;
}

.dropRootClass1 {
  /* width: 45%; */
  width: 56%;
}

.dropRootClass {
  /* width: 45%; */
  width: 65%;
}

.inputBox {
  width: 100%;
  /* width: 45%; */
  border: 1px solid grey;
  border-radius: 3px;
}

.Dropdown-menu {
  max-height: 10rem !important;
}

.MuiInput-input {
  font-size: 14px !important;
  /* padding-left: .5rem!important; */
}

.fontweight500 {
  /* font-size: 14px !important; */
  font-weight: 500;
}

.fontSize14 {
  font-size: .8rem !important;
}

.Dropdown-arrow {
  top: 10px !important;
}

.Dropdown-placeholder {
  font-size: .8rem !important;
}
::placeholder {
  color: red;
}

.scrollClass::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3) !important;
  background-color: #f5f5f5 !important;
}

.scrollClass::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: rgba(51, 51, 51, 0.23) !important;
}

.scrollClass::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 7px !important;
  background-color: #f5f5f5 !important;
}

.Toastify {
  z-index: 10001;
  position: absolute;
}

.Toastify__toast {
  min-height: 47px !important;
}

/* new for psych */
.HeaderElement {
  box-shadow: grey 0px 0px 5px;
  background-color: lightcyan;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 50;
}

.bodyElement {
  background-color: #f8ffff;
}
.mainText{
  font-size: 2rem;
   color: darkgreen; padding-top: 150px !important;
}
.curPtr {
  cursor: pointer;
  /* color: dimgray!important; */
}

a:hover,
a:not([href]):hover {
  color: grey ;
  text-decoration: underline !important;
}

a {
  color: darkgreen !important;
  cursor: pointer;
}

.iconCss {
  font-size: 1.4rem;
  color: darkgreen;
  vertical-align: sub;
  position: relative;
  bottom: 1px;
}
.MuiInputBase-input{
  padding: 8px 12px !important;
}

.iconCss1 {
  font-size: 1.6rem!important;
  color: darkgreen;
  vertical-align: sub;
  position: relative;
  top: 2px;
  cursor: pointer;
}
.submitBtn{
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  font-size: 1rem;
  line-height: 1.4;
  border-radius: 2px;
}
.disabledd{
  fill: grey !important;
  color: grey !important;
}
.nameCssDialog{
  font-weight: bold;
  font-size: 1rem;
  text-align: right;
  padding-bottom: .5rem;
  color: darkgreen;
}
.timeCss{
  position: relative;
  bottom: 6px;
  font-size: .9rem;
}
.nameCss{
  font-weight: bold;
  font-size: 1.1rem;
  color: darkgreen;
}
.fs1_2 {
  font-size: 1.2rem;
}

.fs1_5 {
  font-size: 1.5rem;
}

.MuiDialog-paperWidthSm {
  /* min-width: 25rem;
  max-width: 30rem; */
}
.infoText{
  padding: 16px 24px;
  color: grey;
  font-size: .9rem;
}
.reviewCss{
  font-size: .8rem;
  padding-left: 26px;
  font-weight: bold;
}
.imageCss {
  height: 100%;
  width: 100%;
}

.hrClass {
  /* border: 1px solid black; */
  margin: 0 auto 16px;
  width: 60%;
}
.MuiMenu-paper{
  top:10px !important;
  z-index: 100!important;
}
.buttonAnchor{
  color: white !important;
}
/* end new for psych */
.btn-submit {
  font-size: 14px !important;
  background-color: #005596 !important;
  color: white !important;
}

.timeTop {
  position: relative;
  top: 1.25rem
}

.dropSet {
  position: relative;
  top: 9px;
}

.dark_blue {
  color: #005596;
}

.pageHeader {
  font-size: 16px;
  position: relative;
  top: 0.25rem;
}

.highlight {
  background-color: #006dc8 !important;
  color: white !important;
  border-color: #006dc8 !important;
  border-radius: 0px !important;
}

.noHighlight {
  background-color: white !important;
  color: #006dc8 !important;
  border-color: #006dc8 !important;
  border-radius: 0px !important;
  /* #148ea1 !important; old */
}

.running {
  background-color: white;
  /* #fbbf09; */
  color: #c89807;
  border: 1px solid #c89807;
}

.scheduled {
  background-color: white;
  color: #197bcd;
  border: 1px solid #197bcd;
}

.success1 {
  background-color: white;
  /* #90ee90; */
  color: #51ae52;
  border: 1px solid #51ae52;
}

.failed1 {
  background-color: white;
  color: #ff6767;
  border: 1px solid orangered;
}

.success {
  background-color: #51ae52;
  color: white;
  border: 1px solid black;
}

.failed {
  background-color: orangered;
  color: white;
  border: 1px solid black;
}

.Toastify__close-button {
  margin-right: .5rem !important;
}

.status {
  border-radius: 7px;
  width: 75% !important;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
}

.tooltipClass {
  font-size: 11px;
}

.disableIcon {
  color: lightgray !important
}

.actionHeader .rt-resizable-header-content,
.ReactTable .rt-tbody .rt-td {
  text-align: center;
}

.Summary_table .ReactTable .rt-thead .rt-tr {
  font-size: .8rem !important;
}

.Summary_table .rt-tbody {
  background-color: white !important;
}

.Summary_table .ReactTable .rt-thead.-filters .rt-th select {
  font-size: 12px !important;
  padding: 0px 3px 3px 5px !important;
}

.Summary_table .ReactTable .rt-thead.-filters .rt-th input {
  font-size: 12px !important;
  padding: 2px 3px 3px 5px !important;
}

.Summary_table .ReactTable .rt-thead .rt-tr {
  height: 31px;

  background-color: #d3d3d3;
  color: #2f4d6b;
  font-size: 14px;
  font-weight: 500;
}

.detail_Summary_table .ReactTable .rt-thead .rt-tr {
  background-color: #a2d3ff !important;
  color: black !important;
}

.detail_Summary_table .ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #54f7ff;
}

.detail_Summary_table .ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #54f7ff;
}

.detail_Summary_table .table-status-drop .r-ss-trigger {
  color: #495057 !important;
  font-weight: 400 !important;
  background-color: white !important;
  padding: 2px 0px 0px 2px !important;
  border: none !important;
  height: 22px;
  border-radius: .2rem;
}

.detailsummaryTable {
  border: none !important;
  font-size: .8rem;
}

.noTabeData {
  color: black;
  /* margin-top: 1.1rem;
  margin-bottom: 1.4rem; */
  padding: 0px !important;
}

/* Pagination -- START */

.pagination_value {
  color: #c5213e;
}

.pgshowtxt {
  position: absolute;
  left: 0px;
  font-size: 12px;
}

.Table__pagination {
  background-color: white !important;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
  border-top: 1px solid lightgrey;
}

.Table__pageButton--active {
  font-weight: normal !important;
}

.Table__pageButton.Table__pageButton--active {
  margin-top: 8px !important;
}

.Table__pageButton {
  font-size: .8rem;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0.5rem !important;
}

.Table__pageButton:disabled {
  cursor: not-allowed;
  color: #808080;
}

.Table__pageButton--active {
  color: #45b3e3;
  font-weight: bold;
  padding: 0px 5px !important;
  box-shadow: none !important;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border-radius: 0 !important;
  background-color: #003767;
  color: #fff;
}

.Table__pageButton.Table__pageButton--active {
  margin-top: 10px;
}


/* Pagination -- END */
.disabled {
  color: lightgrey !important;
}

.MuiTypography-body1 {
  font-size: .82rem !important;
  /* top: 1px;
    position: relative; */
}

.MuiSvgIcon-root {
  width: .8em !important;
}

/* .MuiRadio-root{
  padding: 5px;
} */
/* .MuiFormControlLabel-root .PrivateSwitchBase-root-1, .MuiFormControlLabel-root .PrivateSwitchBase-root-8, */
.MuiRadio-root {
  padding: 5px !important;
  padding-right: 8px !important;
}

.positionSet {
  position: relative;
  top: 1px;
  font-weight: 500;
}

.detail_Summary_table .ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #003767;
}

.detail_Summary_table .ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #003767;
}

.MuiFormGroup-root {
  display: inline !important;
}

.mr_6 {
  margin-right: 12rem !important;
  /* 6 old val with 65% now 45 */
}

.mr_15 {
  margin-right: 15rem !important;
}

.mr-6 {
  margin-right: 4rem !important;
}

.MuiTypography-body1 {
  position: relative;
  top: 1.6px;
}

.font_w_500 {
  font-weight: 500;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  position: relative;
  top: 1px;
}

.MuiTypography-h6 {
  font-size: 1.3rem !important;
}

.MuiDialogActions-root {
  padding-right: 24px !important;
}